import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getLeaveform } from "../../api";
import {  ILeaveformDetail, IUser } from "../../types";
import LeaveformDetailSkeleton from "../../components/Leaveform/LeaveformDetailSkeleton";
import { Badge, Box, Button, Flex, Heading, HStack, Stack } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import Footer from "../../components/PDF/Leaveform/Footer";
import Header from "../../components/PDF/Leaveform/Header";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";
import Middle1 from "../../components/PDF/Leaveform/Middle1";

Font.register({
    family: 'Noto Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2',
      },
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2',
        fontWeight: 'bold',
      },
    ],
  });
  
const styles = StyleSheet.create({
    body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Noto Sans',
    fontSize: 10,
    lineHeight: 1.4,
    padding: 16,
    paddingHorizontal: 16,
    height: '100vh',
    display: 'flex',
    size: "A4",
    },
    top: {
        flex: 1.5,
    },
    middle1: {
        flex: 2,
    },
    middle2: {
        flex: 4,
        

    },
    bottom: {
        justifyContent: "flex-end",
        flex: 1.5,
        fontSize: 8,
    },
    body_m: {
        paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px",paddingBottom: "20px"
    },
    reason: {
        display: 'flex',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "50px"
        },
    signature: {
        display: 'flex',
        paddingLeft: "20px",
        paddingRight: "20px",
        },
    signaguresub: {
        flexDirection: 'row',
        justifyContent: "space-between",
    },
})

export default function LeaveformDetail() {
    const { leaveformPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<ILeaveformDetail>([`leaveforms`, leaveformPk], getLeaveform);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = data?.submitter.minimax_number!
    const minimax_role = data?.submitter.role!
    const lid = data?.leaveform_id!
    const lsubmitter = data?.submitter!
    const lname = data?.submitter.name!
    const ldate = data?.submission_date!
    const lreason = data?.reasonForRequest!
    const lremark = data?.remark!
    const lkind = data?.kind!
    const lleaveform_date = data?.leaveform_dates!
    const lleaveTotalDays = data?.leaveTotalDays!
    const lcomment = data?.comment!

    const MyPDF = () => (
        <Document>
            <Page style={styles.body} >
                <View style={styles.top}>
                    <Header kind={lkind}>Leaveform</Header>
                </View>
                <View style={styles.middle1}>
                    <Middle1 kind={lkind} submit_date={ldate} submittername={lname} minimax_id={minimax_number} role={minimax_role} total_days={lleaveTotalDays}></Middle1>
                </View>
                
    
                <View style={styles.middle2}>
                    <View style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <View style={{display: 'flex'}}>
                            <View style={{flexDirection: "row", textAlign: "center"}}>
                                <Text style={{flex: 1, backgroundColor: "#000", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderLeft: "1px solid #000"}}>ID</Text>
                                <Text style={{flex: 6, backgroundColor: "#000", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>From</Text>
                                <Text style={{flex: 6, backgroundColor: "#000", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>To</Text>
                            </View>
                        </View>
                        {data?.leaveform_dates.map((key, index) => {
                            return <View key={index} style={{display: 'flex'}}>
                                <View style={{flexDirection: "row", textAlign: "center"}}>
                                    <>
                                        <Text style={{flex: 1, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", fontSize: "8px"}} >{index+1}</Text>
                                        <Text style={{flex: 6, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.leaveStartDay} / {key.leaveStartMonth} / {key.leaveStartYear}</Text>
                                        <Text style={{flex: 6, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.leaveEndDay} / {key.leaveEndMonth} / {key.leaveEndYear}</Text>
                                    </>
                                </View>
                            </View>
                            ;
                        })}
                    </View>
                    
                    <View style={styles.reason}>
                    <View style={styles.signaguresub}>
                        <View style={{flexDirection: "row" }}>
                            <View style={{flexDirection: "column", width: "48%", border: "1px solid #000"}}>
                                <View style={{backgroundColor: "#000", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>REASON FOR REQUESTED LEAVE</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{}}>
                                        <Text style={{textAlign: "center", paddingRight: "20px", paddingLeft: "20px", color: "#000",  fontSize: "8px", paddingTop: "12px", paddingBottom: "30px", fontFamily: "Noto Sans"}}>{data?.reasonForRequest}</Text>
                                    </View>
                                </View>
                                
                            </View>
                            <View style={{backgroundColor: "#fff", height: "20px", width: "4%", color: "#fff"}}>
                            </View>
                            <View style={{flexDirection: "column", width: "48%",  border: "1px solid #000"}}>
                                <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>REMARK</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{}}>
                                        <Text style={{textAlign: "center", paddingRight: "20px", paddingLeft: "20px", color: "#000",  fontSize: "8px", paddingTop: "12px", paddingBottom: "30px", fontFamily: "Noto Sans"}}>{data?.remark}</Text>

                                    </View>
                                    
                                </View>
                                
                            </View>
                            
                        </View>
                        
                    </View>
                    {data?.comment !== "" &&
                        <>
                        <View style={{backgroundColor: "red", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px", marginTop: "10px", textAlign: "center"}}>
                            <Text>ADDITIONAL COMMENT</Text>
                        </View>
                        <View style={{textAlign:"center"}}>                                        
                            <Text style={{textAlign: "center", paddingRight: "20px", paddingLeft: "20px", color: "#000",  fontSize: "8px", paddingTop: "15px", paddingBottom: "15px", fontFamily: "Noto Sans"}}>{data?.comment}</Text>
                        </View>
                        </>
                    }
                </View>   
                </View>
                
                <View style={styles.body_m}>
                    <View style={styles.signature}>
                        <View style={styles.signaguresub}>
                            <View style={{flexDirection: "row" }}>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#f0f0f0", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center", color: "#292929"}}>REQUESTER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>{lname}</Text>
                                                <Text style={{ color: "#292929", fontSize: "8px", marginTop: "8px"}}>{ldate}</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D",  fontSize: "7px", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                                </View>

                                {minimax_role == "Sales Team" && 

                                <>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>TEAM MANAGER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Charlie Hong</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                </>
                                }

                                {minimax_role == "Management Team" &&
                                <>
                                
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>TEAM MANAGER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                </>
                                }


                                {minimax_role == "TS Team" &&
                                <>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>TEAM MANAGER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>William Chung</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                </>
                                }

                                {minimax_role == "Marketing Team" &&
                                <>
                                
                                <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>TEAM MANAGER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Daniel Song</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                </>
                                }

                                {minimax_role == "Digital" &&
                                <>
                                
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>DIRECTOR</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                </>
                                }

                                

                                <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>DIRECTOR</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            
                        </View>
                    </View>
                    
                </View> 
                <View style={styles.bottom}>
                    
                    {/* <ProposalSignature/> */}
                    <Footer/>
                </View>
                {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
            </Page>
        </Document>
        )
    function CheckUser() {
        if(userData?.role === "Boss" || "TS" || "Admin" || "Sales" || "Digital" || "Marketing" || "Manager" && data?.leaveform_id) {
            return <>
                <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/leaveforms/`}><Button colorScheme='facebook'>&#x2190; Leave Forms 리스트</Button></Link></Box>
                    
                    </HStack>
                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.submitter.name}_${data?.leaveform_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                    <Box>Team: {minimax_role}</Box>
                    <Box>Leave Form ID: {lid}</Box>
                    <Box>Name: {lname}</Box>
                    <Box>Submit Date: {ldate}</Box>
                    <Box>Reason: {lreason}</Box>
                    <Box>Remark: {lremark}</Box>
                    <Box>Leave Type: {lkind}</Box>
                    <Box>Total Day(s): {lleaveTotalDays}</Box>
                    <Box>{lcomment}</Box>

                    <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                    <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                        <Box flex="1">ID</Box>
                        <Box flex="6">Start</Box>
                        <Box flex="6">END</Box>
                    </Flex>
                    {data?.leaveform_dates.map((key, index ) => <Flex key={index}>
                        <>
                        <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                        <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.leaveStartDay} / {key.leaveStartMonth} / {key.leaveStartYear}</Box>
                        <Box flex="6 " pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.leaveEndDay} / {key.leaveEndMonth} / {key.leaveEndYear}</Box>
                        </>
                    </Flex>
                    )}
`                   </Box>
                </Box>
                </>
        } 
        // else if (userData?.role === "Sales Team" && data?.board_id) {
        //     if(minimax_number === data?.submitter.minimax_number) {
        //         return <>
        //         <Heading>{data?.title}</Heading>
        //         <Box><pre>{data?.description}</pre></Box>
        //         </>
        //     } else {
        //         navigate("/")
        //     }
        // } 
        
        else {
            navigate("/")
        }
        return <Box>{data?.leaveform_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <LeaveformDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}